/* ***** AppBarWrapper ***** */
.app-bar-btn {
  width: max-content;
  border-radius: 30px !important;
  text-transform: none !important;
}

/* ***** preOnboarding (todos) ***** */
.pre-onboarding-main-container {
  margin-top: 80px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.pre-onboarding-title {
  text-align: left;
  font-size: 36px !important;
  font-weight: 600 !important;
  color: #4a4a4a !important;
  line-height: 1.1 !important;
}

.pre-onboarding-subtitle {
  text-align: left;
  font-size: 24px !important;
  font-weight: 500 !important;
  color: #4a4a4a !important;
  line-height: 1.1 !important;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}

@media only screen and (max-width: 1024px) {
  .hide-xs-sm {
    display: none;
  }

  .pre-onboarding-title {
    font-size: 28px !important;
  }

  .pre-onboarding-subtitle {
    font-size: 18px !important;
  }

}

/* ***** preOnboardingPersonalLoan ***** */
.personal-banner {
  position: absolute;
  top: 0;
  width: 50%;
  z-index: -1;
}

/* ***** preOnboardingRechargeLoan ***** */
.recharge-banner {
  position: absolute;
  top: 100px;
  width: 50%;
  z-index: -1;
}

/* ***** preOnboardingServicesLoan ***** */
.services-banner {
  position: absolute;
  top: 100px;
  z-index: -1;
}

/* ***** preOnboardingServicesLoan ***** */
.uber-banner {
  position: absolute;
  top: 0;
  width: 50%;
  z-index: -1;
}

.uber-code-not-Found {
  font-size: 14px !important;
}

/* ***** IdentityResolverStep ***** */
.identity-resolver-title {
  text-align: left !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #4a4a4a !important;
}

.identity-resolver-container {
  display: flex !important;
}

.identity-resolver-list-item {
  background-color: #F5F5F5;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  padding: 8px 16px !important;
  margin-top: 20px;
  text-align: left;
  width: 100%;
}

.identity-resolver-legal-question {
  text-align: left !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

/* ***** CardButton ***** */
.card-button {
  width: 100% !important;
}

.card-button-title {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.card-button-subtitle {
  font-weight: 400 !important;
  font-size: 12px !important;
}

/* ***** SelectedCustomer ***** */
.selected-customer-name {
  text-align: left !important;
  font-size: 16px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
}

.selected-customer-btn {
  padding: 0px !important;
}

.selected-customer-mail-label {
  font-size: 14px !important;
  text-align: left;
  display: flex !important;
  align-items: center;
}

.selected-customer-mail-edit-btn {
  padding: 0px !important;
  text-transform: none !important;
}

/* ***** CellphoneStep ***** */
.cellphone-caption {
  font-size: 14px !important;
  text-align: left;
}

/* ***** VerificationCodeStep ***** */
.verification-code-container {
  margin-top: 20px;
  display: flex !important;
  background-color: #f5f5f5;
  padding: 8px 16px;
}

.verification-code-label {
  font-size: 16px !important;
  text-align: left;
  align-items: center;
}

.verification-code-input input {
  font-size: 24px !important;
  color: #F2025D;
}

.verification-code-edit-btn {
  padding: 0px !important;
  text-transform: none !important;
}

.terms-conditions-container {
  display: flex !important;
  flex-wrap: nowrap !important;
  align-items: center !important;
}

.terms-conditions {
  font-size: 14px;
  text-align: left;
}

.terms-conditions-check {
  padding-left: 10px !important;
}

.terms-conditions-button {
  font-size: 14px !important;
  padding: 0px !important;
  padding-bottom: 2px !important;
  text-transform: none !important;
}

.count-down {
  text-align: left;
}

.count-down-btn {
  padding: 0px !important;
  font-size: 14px !important;
  text-transform: none !important;
}

.resend-message {
  text-align: left;
}

/* ***** PreOnboardingOnePage ***** */
.next-steps-title {
  color: #F2025D;
  font-size: 24px !important;
  font-weight: 700 !important;
  text-align: left;
}

/* ***** CardImage ***** */
.card-image-icon {
  max-width: 40px;
}

.card-image-description {
  text-align: left;
  font-size: 12px !important;
  font-weight: 600 !important;
}

@media only screen and (max-width: 800px) {
  .main-container-body {
    min-height: 500px;
  }

  .identity-resolver-list-item span {
    font-size: 14px !important;
  }
  
  .verification-code-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .verification-code-container button {
    padding-left: 0px !important;
    margin-top: 12px;
  }

  .terms-conditions {
    font-size: 12px !important;
  }
    
  .terms-conditions-button {
    font-size: 12px !important;
    padding-bottom: 2px !important;
  }
}