/* ***** InstallmentCard ***** */
.installment-card-selected {
  border-color: #F2025D !important;
}

.installment-card-save {
  color: #F2025D !important;
}

.installment-card-detail {
  font-size: 12px !important;
  color: #afafaf;
}

.installment-card-previous-price {
  padding-left: 10px;
  text-decoration: line-through;
  color: #afafaf;
}

.installment-card-radio {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* ***** banderin de descuento ***** */
.container-ribbon {
  position: relative;
}

.promo-ribbon {
  background-image: url('../imgs/bg_ribbon.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  padding: 10px 2px;
  height: 50px;
  width: 50px;
  text-align: center;
}

.position-ribbon {
  position: absolute;
  top: 0px;
  right: 10px;
}

.benefit-rate-card {
  border-radius: 15px;
  box-shadow: 0px 2px 6px #CBCBCB;
  padding: 15px;
  margin-top: 30px;
}

.benefit-rate h4 {
  font-family: 'Open sans';
  font-weight: 700;
  font-size: 20px;
  color: #F2025D;
  padding-left: 15px;
}

.benefit-rate p {
  font-family: 'Open sans';
  font-size: 13px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: left;
}

.benefit-first-installment-card {
  border-radius: 15px;
  box-shadow: 0px 2px 6px #CBCBCB;
  padding: 15px;
  margin-top: 30px;
}

.benefit-first-installment.message {
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
}

.benefit-first-installment.message h4 {
  margin: 0;
  font-weight: 700;
  padding-left: 15px;
}

.benefit-first-installment {
  text-align: left;
}

.benefit-first-installment p {
  font-family: 'Open sans';
  font-size: 13px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 5px;
  margin-right: 5px;
}

.benefit-first-installment.installment {
  font-size: 16px;
  font-weight: 700;
}

.benefit-first-installment a {
  color: #4a4a4a;
  text-decoration: underline;
  font-family: 'Open sans';
  font-size: 13px !important;
  padding-left: 5px;
}

.postpone-first-installment-card {
  border-radius: 15px;
  box-shadow: 0px 2px 6px #CBCBCB;
  padding: 15px;
  margin-top: 30px;
}

.postpone-first-installment {
  text-align: left;
}

.postpone-first-installment h4 {
  font-family: 'Open sans';
  font-weight: 700;
  font-size: 20px;
  color: #F2025D;
  padding-left: 15px;
}

.postpone-first-installment p {
  font-family: 'Open sans';
  font-size: 13px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 5px;
  margin-right: 5px;
}


.MuiSlider-valueLabel {
  background-color: unset !important;
  color: #ff4081 !important;
  font-size: 16px !important;
  font-family: 'Open Sans' !important;
}

.simple-offer-installment-message {
  text-align: left;
  font-family: 'Poppins',sans-serif !important;
  font-size: 24px !important;
}

@media only screen and (max-width: 1024px) {
  .promo-ribbon {
    background-image: url('../imgs/bg_ribbon.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
    padding: 10px 2px;
    height: 40px;
    width: 40px;
    text-align: center;
  }
}