.dual-offer-container {
  box-shadow: 0 4px 6px rgba(0,0,0,.25);
  border-radius: 0px 0px 10px 10px;
}

.dual-offer-tab-left {
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  border-radius: 5px 0px 0px 0px;
}

.dual-offer-tab-right {
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  border-radius: 0px 5px 0px 0px;
}

.dual-offer-tab {
  background-color: #F3F3F3;
  cursor: pointer;
  padding: 15px;
}

.dual-offer-tab-selected {
  background-color: #F2025D;
  padding: 15px;
}

.dual-offer-tab-title {
  color: #707070;
  font-family: 'Open Sans',sans-serif !important;
  font-size: 14px !important;
}

.dual-offer-tab-title-selected {
  font-weight: 900 !important;
  color: white;
  font-family: 'Open Sans',sans-serif !important;
  font-size: 14px !important;
}

.dual-offer-tab-content-container {
  border: 1px solid #ddd;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-radius: 0px 0px 10px 10px;
}

.dual-offer-tab-content-title {
  text-align: left;
  font-size: 14px !important;
  font-family: 'Open Sans',sans-serif !important;
}

.dual-offer-tab-content-section {
  text-align: left;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.dual-offer-tab-content-disclaimer {
  font-size: 12px !important;
  font-family: 'Open Sans',sans-serif !important;
}