
.main-container-body {
  margin-bottom: 50px;
}

.customer-data-main-container {
  margin-top: 80px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.customer-data-title {
  text-align: center !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 22px !important;
  font-weight: 600 !important;
}

.customer-data-prepaid-problems {
  font-family: 'Open Sans', sans-serif !important;
  text-align: left !important;
  font-size: 16px !important;
}

.customer-data-cbu-loaded {
  box-sizing: border-box;
	background: #FFFFFF;
	border: 1px solid #D8D8D8;
	border-radius: 15px;
  padding: 15px;
  margin-bottom: 5px;
}

.customer-data-loaded-info {
  text-align: center;
}

.customer-data-show-cbu-btn {
  padding: 0px !important;
  text-transform: none !important;
  font-weight: 100 !important;
}

.customer-data-revolving-title {
  font-family: 'Open Sans', sans-serif !important;
  text-align: left !important;
  font-weight: 600 !important;
  color: #00000061;
}

.customer-data-send-code {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  font-style: italic !important;
}

.customer-data-alert {
  font-family: 'Open Sans', sans-serif !important;
  text-align: left !important;
  font-size: 14px !important;
}

.customer-data-custom-hint {
  color: #4a4a4a !important;
  font-family: 'Poppins', sans-serif !important;
  text-align: left !important;
  font-size: 11px !important;
  margin-top: 3px !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
}

.customer-data-button-tertiary-ligth {
  text-transform: none !important;
  font-size: 10px !important;
  font-weight: 100 !important;
}

.customer-data-prepaid-card-new {
  background-color: #FCB81380 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  border-radius: 54px !important;
  padding: 2px 8px !important;
  margin-left: 10px !important;
}

.customer-data-cardinal-text {
  font-family: 'Open Sans', sans-serif !important;
  text-align: left;
  font-size: 16px;
}

.customer-data-show-details-btn {
  padding: 0px !important;
  text-transform: none !important;
  text-decoration: underline !important;
}
