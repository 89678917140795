.onboarding-qr-code-title {
  margin-top: 20px !important;
  font-weight: 500 !important;
  font-family: 'Poppins',sans-serif !important;
  font-size: 24px !important;
  color: #4a4a4a !important;
}

.onboarding-qr-code-description {
  margin-top: 20px !important;
  font-family: 'Open Sans',sans-serif !important;
  color: #afafaf !important;
  font-size: 14px !important;
}

.onboarding-qr-code-cta {
  font-family: 'Open Sans',sans-serif !important;
  font-size: 14px !important;
  padding: 0px !important;
  margin-left: 5px !important;
  text-transform: lowercase !important;
}