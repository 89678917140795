.footer-cc {
  font-size: 80%;
  margin-left: 20px;
  margin-right: 20px;
  border-top: 1px solid lightgray;
}

.footer {
  background-color: #f5f5f5;
  color: #4a4a4a;
  padding-top: 36px !important;
  padding-left: 80px !important;
  padding-right: 40px !important;
  padding-bottom: 20px !important;
}

.footerMediumText {
  font-size: 14px !important;
  text-align: center;
}

.footerSmallText {
  font-size: 12px !important;
  text-align: center;
}

.footerBigText {
  font-size: 48px !important;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .footer {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .footerMediumText {
    font-size: 12px !important;
  }
  
  .footerSmallText {
    font-size: 10px !important;
  }
  
  .footerBigText {
    font-size: 32px !important;
  }
}