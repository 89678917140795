.next-steps-first-box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.next-steps-first-subbox {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.next-steps-imgs {
  width: 40px;
}

.next-steps-text-grey {
  color: #afafaf;
  font-size: 12px !important;
}

.next-steps-modal-container {
  padding: 15px 50px;
}

.next-steps-modal-title {
  font-family: 'Poppins', sans-serif !important;
  font-size: 24px !important;
  text-align: center;
}

.next-steps-modal-subtitle {
  font-size: 16px !important;
  text-align: center;
}

.next-steps-modal-box {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.next-steps-modal-box-img {
  width: 60px;
}

.next-steps-modal-box-title {
  color: #E91E63;
  font-family: 'Poppins', sans-serif !important;
  font-size: 18px !important;
}

.next-steps-modal-box-description {
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
  text-align: center;
}

.next-steps-section-title {
  text-align: center;
  color: #F2025D;
  font-size: 24px !important;
  font-weight: 700 !important;
  margin-bottom: 20px !important;
  font-family: 'Poppins', sans-serif !important;
}

.next-steps-taxes-main {
  font-size: 20px !important;
  text-align: center;
  font-family: 'Open Sans', sans-serif !important;
}

.next-steps-taxes-sub {
  color: #afafaf;
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
}