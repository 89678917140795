.document-load-step-number {
  color: #FFF !important;
  width: 50px !important;
  height: 50px !important;
  line-height: 50px !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
  border: 1px solid #F2025D !important;
  background-color: #F2025D !important;
  text-align: center !important;
  font-family: 'Poppins',sans-serif !important;
  font-weight: 600 !important;
  font-size: 30px !important;
}

.document-load-step-img {
  margin-left: 20px !important;
  width: 100px !important;
}

.document-load-step-item {
  font-family: 'Poppins',sans-serif !important;
  font-size: 18px !important;
}