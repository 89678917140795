.notification-subscription-container-top {
  z-index: 2000 !important;
  background-color: white !important;
  height: max-content !important;
  max-width: 400px !important;
  margin-left: 0px !important;
  margin-right: 20px !important;
  padding: 15px !important;
  position: fixed !important;
  border: 1px solid #bebebe !important;
  border-radius: 10px !important;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5) !important;
  top: 20px !important;
}

.notification-subscription-container-bottom {
  z-index: 2000 !important;
  background-color: white !important;
  height: max-content !important;
  max-width: 400px !important;
  margin-left: 0px !important;
  margin-right: 20px !important;
  padding: 10px !important;
  position: fixed !important;
  border: 1px solid #bebebe !important;
  border-radius: 10px !important;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5) !important;
  bottom: 20px !important;
}

.notification-subscription-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}