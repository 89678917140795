.onboarding-header {
  font-family: 'Open Sans',sans-serif !important;
  font-size: 12px !important;
  text-align: left;
}

.onboarding-header-link {
  font-family: 'Open Sans',sans-serif !important;
  font-size: 12px !important;
  text-align: left;
}

.onboarding-header span {
  display: inline !important;
}
