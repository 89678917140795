.summary-payment-method-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.summary-payment-method-img {
  background-color: #F5F5F5 !important;
  padding: 11px !important;
  margin-right: 10px !important;
  border-radius: 3px !important;
}

.summary-payment-method {
  text-align: left !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
  color: #4a4a4a !important;
}