.recharge-step-title {
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
  font-size: 32px !important;
  line-height: 48px !important;
  color: #212121;
  text-align: left;
}

.recharge-step-subtitle {
  font-family: 'Open Sans' !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 27.24px !important;
  color: #212121;
  text-align: left;
}

.recharge-company-card {
  border: 2px solid #E0E0E0;
  border-radius: 5px;
  width: 100px;
  height: 67px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 10px 0px 0px;
}

.recharge-company-card-selected {
  border: 2px solid #F2025D;
  border-radius: 5px;
  width: 100px;
  height: 67px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 10px 0px 0px;
}

.recharge-company-card:hover {
  border: 2px solid #F2025D;
}

.recharge-recharge-amount {
  font-family: 'Open Sans' !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 21.79px !important;
  color: #212121;
  text-align: left;
}

@media only screen and (max-width: 1024px) {
  .recharge-step-title {
    font-size: 22px !important;
    line-height: 24px !important;
  }

  .recharge-step-subtitle {
    font-size: 18px !important;
    line-height: 18px !important;
  }
}