.upload-document-title {
  font-family: 'Poppins', sans-serif !important;
  font-size: 28px !important;
  font-weight: 500 !important;
  text-align: left !important;
}

.upload-document-description {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
  text-align: left !important;
}

.upload-document-fileupload button {
  text-transform: none !important;
  border-radius: 20px !important;
  height: 20px !important;
}

@media only screen and (max-width: 800px) {
  .upload-document-title {
    font-weight: bold !important;
    text-align: center !important;
  }
}