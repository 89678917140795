.main-container-body {
  margin-bottom: 50px;
}

.onboarding-final-main-container {
  margin-top: 80px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
@media only screen and (min-width: 600px) {
  .onboarding-final-main-container {
    margin-top: 80px;
    padding-left: 60px !important;
    padding-right: 20px !important;
  }
}
@media only screen and (min-width: 1024px) {
  .onboarding-final-main-container {
    margin-top: 100px;
    padding-left: 100px !important;
    padding-right: 20px !important;
  }
}

.onboarding-final-title {
  text-align: left;
  font-size: 24px !important;
  font-weight: 400 !important;
  color: #4a4a4a !important;
  line-height: 1.1 !important;
}

.onboarding-final-subtitle {
  text-align: left;
  font-size: 20px !important;
  font-weight:  700 !important;
  color: #4a4a4a !important;
  line-height: 1.1 !important;
}

.onboarding-final-subtitle-long {
  text-align: left;
  font-size: 18px !important;
  font-weight:  700 !important;
  color: #4a4a4a !important;
  line-height: 1.5 !important;
}

.onboarding-final-subtitle-pink {
  text-align: left;
  font-size: 18px !important;
  font-weight:  700 !important;
  color: #F2025D !important;
  line-height: 1.1 !important;
}

.onboarding-final-text {
  text-align: left;
  font-size: 16px !important;
  font-weight:  400 !important;
  color: #4a4a4a !important;
  line-height: 1.1 !important;
}

.onboarding-final-dismiss-text {
  font-family: 'Open Sans',sans-serif !important;
  font-size: 12px !important;
  line-height: 1.1 !important;
  color: #4a4a4a !important;
}

.onboarding-final-dismiss-button {
  font-family: 'Open Sans',sans-serif !important;
  font-size: 12px !important;
  line-height: 1.1 !important;
  color: #4a4a4a !important;
  text-decoration: underline !important;
  text-transform: lowercase !important;
  padding: 0px !important;
  padding-bottom: 4px !important;
  min-width: fit-content !important;
}

.onboarding-final-ecommerce-arrow {
  font-family: 'Open Sans',sans-serif !important;
  font-size: 14px !important;
  line-height: 1.1 !important;
  color: #F2025D !important;
  text-transform: lowercase !important;
  padding: 0px !important;
  padding-bottom: 4px !important;
  min-width: fit-content !important;
}

.onboarding-final-ecommerce-title {
  text-align: left;
  font-size: 28px !important;
  font-weight: 700 !important;
  color: #4a4a4a !important;
  line-height: 1.5 !important;
  text-align: center;
}

.onboarding-final-ecommerce-text {
  font-family: 'Open Sans',sans-serif !important;
  font-size: 20px !important;
  line-height: 1.1 !important;
  color: #4a4a4a !important;
}

.onboarding-final-ecommerce-countdown {
  padding: 15px;
  background-color: #d9edf7;
  color: #31708f;
  border-color: #bce8f1;
  border: 1px solid transparent;
  border-radius: 4px;
}

.onboarding-final-nps-score {
  font-family: 'Open Sans',sans-serif !important;
  font-size: 14px !important;
}