.summary-title {
  text-align: center !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  color: #212121 !important;
  text-align: left !important;
}

.summary-description {
  margin-top: 20px !important;
  text-align: left !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.summary-signature-container div {
  width: 100% !important;
}
.summary-signature-container button {
  text-transform: capitalize !important;
  border-radius: 20px !important;
  width: 90% !important;
}

.summary-download-agreement {
  margin-top: 20px !important;
  text-transform: none !important;
  width: 100%;
}

.summary-sign-agreement {
  margin-top: 20px !important;
  text-align: left !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.summary-signature-disclaimer {
  margin-top: 10px !important;
  text-align: left !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.summary-footer {
  margin-top: 30px !important;
  text-align: justify !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 10px !important;
  font-weight: 500 !important;
}

.summary-interest-taxes {
  margin-top: 30px !important;
  text-align: left !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.summary-second-column {
  background-color: #f5f5f5 !important;
  padding-top: 80px !important;
}

.summary-terms-and-conditions label {
  margin-right: 5px !important;
}

.summary-terms-and-conditions label span {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
}

.summary-terms-and-conditions-agreement {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
  padding-bottom: 2px !important;
}

.summary-terms-and-conditions-cta-container {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: white;
  border-top: 2px solid #f5f5f5;
}

@media only screen and (max-width: 800px) {
  .summary-title {
    font-size: 20px !important;
  }
}