
.whatsapp-contact-text {
  font-family: 'Open Sans',sans-serif !important;
  font-size: 14px !important;
  font-weight:  400 !important;
  line-height: 1.1 !important;
  color: #4a4a4a !important;
}

.whatsapp-contact-button {
  font-family: 'Open Sans',sans-serif !important;
  font-size: 14px !important;
  line-height: 1.1 !important;
  font-weight:  700 !important;
  color: #4a4a4a !important;
  text-decoration: underline !important;
  text-transform: lowercase !important;
  padding: 0px !important;
  padding-bottom: 4px !important;
  min-width: fit-content !important;
}
