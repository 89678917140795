@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Open sans", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color: #4a4a4a!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-primary {
  border-radius: 25px !important;
  text-transform: none !important;
  font-size: 14px !important;
  height: 45px;
}

.h2-subtitle {
  font-family: 'Open Sans',sans-serif;
  font-size: 24px;
  color: #4a4a4a;
}

.selected-user {
  text-transform: capitalize;
  font-weight: 700!important;
  color: #4a4a4a;
  margin-top: 10px!important;
  font-size: 18px!important;
}

.subtitle {
  font-size: 30px!important;
  color: #4a4a4a;
  text-transform: none!important;
  font-family: inherit!important;
  font-weight: 500!important;
  line-height: 1.1!important;
}

.input-cellphone-evaluation-hint {
  font-size: 12px!important;
}

.identity-title {
  text-align: left!important;
  padding-left: 15px!important;
  margin-bottom: 10px!important;
  font-family: inherit!important;
  font-weight: 500!important;
  line-height: 1.1!important;
  font-size: 30px!important;
  color: #4a4a4a!important;
}

.terms-conditions {
  font-size: 14px !important;
}

.terms-conditions-link {
  color: #E91E63;
}

.terms-check {
  padding: 0px!important;
  padding-right: 1px!important;
}

.verification-code-cellphone {
  font-size: 14px!important;
}

.count-down {
  font-size: 12px!important;
}

.identity-ok {
  vertical-align: middle;
  color: #02c66a;
}

.capitalize {
  text-transform: capitalize;
}

.branch-icon {
  width: 15px;
  margin-right: 15px;
}

.branch-location {
  display: flex;
  align-items: center;
}

.display {
  font-family: 'Poppins' !important;
  font-size: 36px !important;
  line-height: 54px !important;
  font-weight: 700 !important;
  margin-bottom: 12px !important;
}

.preonboarding {
  margin-top: 48px !important;
  padding-left: 80px !important;
}

.preonboarding-flow {
  padding-top: 40px !important;
  padding-right: 80px !important;
}

.preonboarding .subtitle {
  font-size: 20px !important;
  margin-bottom: 32px !important;
}

.preonboarding h1 {
  margin-bottom: 8px !important;
}

.preonboarding h2 {
  margin-bottom: 6px !important;
}

.what-is {
  padding-top: 29px !important;
  padding-left: 80px !important;
  padding-bottom: 42px;
  background-color: #fef2f7;
}

.what-is h3 {
  font-family: 'Poppins' !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 36px !important;
  color: #F2025D;
  margin-bottom: 12px !important;
  text-align: left;
}

.what-is ul {
  margin-top: 36px !important;
  padding-left: 20px !important;
}

.what-is p {
  font-size: 16px !important;
  text-align: left;
}

.next-steps {
  background-color: #fffbf3;
  padding-top: 36px !important;
  padding-left: 80px !important;
  padding-bottom: 58px;
}

.next-steps h4 {
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  color: #F2025D;
  text-align: left;
  margin-bottom: 36px;
}

.next-steps p {
  font-size: 16px !important;
  text-align: left;
  margin-left: 18px;
}

@media only screen
and (max-device-width: 600px) {
  .logo-cc {
    width: 130px;
    margin-top: 5px;
  }
}

@media only screen and (max-device-width: 800px) {
  .display {
    font-size: 28px !important;
    line-height: 38px !important;
    margin-bottom: 8px !important;
  }

  .preonboarding-flow {
    padding-top: 40px !important;
    padding-right: 24px !important;
  }

  .preonboarding {
    margin-top: 32px !important;
    padding-left: 24px !important;
  }

  .preonboarding .subtitle {
    font-size: 16px !important;
    margin-bottom: 32px !important;
  }

  .preonboarding h2 {
    font-size: 20px !important;
  }

  .preonboarding h1 {
    font-size: 20px;
  }

  .what-is {
    padding-top: 28px !important;
    padding-left: 24px !important;
    padding-right: 24px;
    padding-bottom: 28px;
    background-color: #fef2f7;
  }
  
  .what-is h3 {
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 22px !important;
    margin-bottom: 28px !important;
  }
  
  .what-is ul {
    margin-top: 16px !important;
    padding-left: 20px !important;
  }
  
  .what-is p {
    font-size: 14px !important;
  }

  .next-steps {
    padding-top: 28px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    padding-bottom: 32px !important;
  }
  
  .next-steps h4 {
    font-size: 16px !important;
    margin-bottom: 28px;
  }
  
  .next-steps p {
    font-size: 14px !important;
    margin-left: 16px;
  }
}