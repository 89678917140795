.greetings-loan-title {
  font-size: 30px !important;
  line-height: 50px !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 700 !important;
  text-align: left;
}

.greetings-loan-subtitle {
  font-size: 30px !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: bold !important;
  text-align: left;
}

.greetings-renewer-prepaid-description {
  font-size: 14px !important;
  text-align: left;
}

.greetings-dual-title {
  color: #4a4a4a !important;
  text-align: left;
  line-height: 50px !important;
  font-weight: normal !important;
}

.greetings-dual-subtitle {
  text-align: left;
  line-height: 40px !important;
}

.greetings-dual-description {
  margin-top: 10px !important;
  text-align: left;
  font-size: 14px !important;
  line-height: 20px !important;
}

.greetings-payday-title {
  text-align: left;
  font-size: 30px !important;
  line-height: 40px !important;
}

.greetings-recharge-title {
  text-align: left;
  font-weight: normal !important;
}

.greetings-recharge-container {
  border-radius: 5px;
  margin-top: 20px;
  padding: 15px;
  max-height: 50px;
  min-height: 50px;
  text-align: center;
  background-color: #F3F3F3;
  display: flex;
  align-items: center;
}

.greetings-recharge-logo {
  max-width: 50px;
}

.greetings-recharge-phone {
  margin-left: 30px !important;
  font-family: 'Open Sans' !important;
  font-size: 18px !important;
}

.greetings-recharge-edit {
  margin-left: 30px !important;
  font-family: 'Open Sans' !important;
}

.greetings-service-title {
  text-align: left;
  font-size: 30px !important;
}

.greetings-bnpl-return {
  font-family: 'Open Sans' !important;
}

.greetings-bnpl-return-icon {
  font-size: 14px !important;
  color: #e72064;
}

.greetings-bnpl-title {
  font-size: 30px !important;
  font-weight: normal !important;
}

.greetings-bnpl-commerce-logo {
  max-height: 50px;
}

.greetings-showcase-title {
  font-size: 30px !important;
  font-weight: normal !important;
  text-align: left;
}

@media only screen and (max-width: 800px) {
  .greetings-loan-title {
    font-size: 22px !important;
    line-height: 30px !important;
  }
  
  .greetings-loan-subtitle {
    font-size: 22px !important;
  }

  .greetings-payday-title {
    font-size: 22px !important;
    line-height: 30px !important;
  }

  .greetings-service-title {
    font-size: 22px !important;
  }

  .greetings-bnpl-title {
    font-size: 22px !important;
  }

  .greetings-bnpl-commerce-logo {
    max-height: 40px;
  }

  .greetings-showcase-title {
    font-size: 22px !important;
  }
}