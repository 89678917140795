.summary-detail-box {
  background-color: #F5F5F5 !important;
  padding: 20px !important;
}

.summary-detail-title {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  color: #212121 !important;
}

.summary-detail-item {
  font-family: 'Open Sans' !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: left !important;
  padding-top: 5px !important;
}

.summary-detail-value {
  font-family: 'Open Sans' !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  text-align: right !important;
  padding-top: 5px !important;
}

.summary-detail-benefit {
  font-family: 'Open Sans' !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: left !important;
  color: #F2025D !important;
}

@media only screen and (max-width: 800px) {
  .summary-detail-box {
    padding: 10px !important;
  }
}



.summary-detail-left {
  text-align: left !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
  margin-top: 5px !important;
  color: #4a4a4a !important;
}

.summary-detail-right {
  text-align: right !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
  margin-top: 5px !important;
  color: #4a4a4a !important;
}

.summary-detail-left-xs {
  text-align: left !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 12px !important;
  color: #4a4a4a !important;
}

.summary-detail-right-xs {
  text-align: right !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 12px !important;
  color: #4a4a4a !important;
}

.summary-detail-cfta {
  font-size: 30px !important;
  font-family: 'Open Sans', sans-serif !important;
  margin-top: 20px !important;
  color: #4a4a4a !important;
}

.summary-detail-tna {
  font-size: 14px !important;
  font-family: 'Open Sans', sans-serif !important;
  color: #4a4a4a !important;
}

.summary-detail-tea {
  font-size: 14px !important;
  font-family: 'Open Sans', sans-serif !important;
  color: #4a4a4a !important;
}