.onboarding-slider-box {
  display: flex;
  align-items: center;
}

.onboarding-slider-description {
  font-size: 28px !important;
  font-weight: 600 !important;
  text-align: left;
  font-family: 'Poppins',sans-serif !important;
}

.onboarding-slider-amount {
  display: inline;
  color: #F2025D;
  font-size: 28px !important;
  text-decoration: underline;
  font-weight: 600 !important;
  font-family: 'Roboto',sans-serif !important;
  cursor: pointer;
  margin-left: 5px !important;
}

.onboarding-slider-input-box {
  display: inline-block;
  max-width: 120px;
  margin-left: 5px !important;
}

.onboarding-slider-input {
  color: #F2025D;
  font-size: 28px !important;
  font-family: 'Roboto',sans-serif !important;
  max-width: 200px;
}

@media only screen and (max-width: 800px) {
  .onboarding-slider-description {
    font-size: 22px !important;
  }
}