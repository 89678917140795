.upload-document-completed-title {
  font-family: 'Poppins', sans-serif !important;
  font-size: 36px !important;
  font-weight: 600 !important;
  text-align: left !important;
}

@media only screen and (max-width: 800px) {
  .upload-document-completed-title {
    font-size: 28px !important;
  }
}