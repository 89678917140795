.services-title {
  font-family: 'Poppins' !important;
  font-size: 28px !important;
  font-weight: 600 !important;
  color: #4A4A4A !important;
  margin-top: 80px !important;
}

.services-subtitle {
  color: #424242 !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  margin-top: 20px !important;
}

.services-search-hint {
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  margin-top: 35px !important;
  margin-bottom: 20px !important;
}

.services-search-button {
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  margin-left: 6px !important;
}

.services-title-companies {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 22px !important;
}

.services-banner {
  position: absolute;
  top: 40px;
  width: 35%;
  z-index: -1;
}

.services-logo-container {
  padding-top: 10px;
  padding-bottom: 40px;
}

.services-logo-company-img {
  display: flex;
  cursor: pointer;
  max-width: 105px;
}

.services-types-container {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.services-logo-img {
  text-align: center;
  max-width: 55px;
  padding: 3px;
}

.services-card {
  width: 100% !important;
  border: 1px solid #9B9B9B !important;
  border-radius: 5px !important;
  padding: 12px 12px 12px 16px !important;
  margin-bottom: 12px !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
}

.services-input {
  margin: 0px !important;
  margin-top: 22px !important;
  text-align: left !important;
}

.services-service-selected {
  color: #4A4A4A !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  margin-bottom: 0px !important;
}

.services-input-label {
  margin-top: 18px !important;
  color: #4A4A4A !important;
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
}

.services-warning {
  display: flex !important;
  align-items: flex-start !important;
  background-color: #FFF9E5 !important;
  padding: 15px !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  gap: 10px !important;
  margin-top: 20px !important;
}

.MuiTextField-root {
  max-width: 100% !important;
  min-width: 100% !important;
  width: 100% !important;
}

.font-family-OpenSans {
  font-family: 'Open Sans' !important;
}

.amount-to-pay {
  margin-bottom: 10px !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.amount-to-pay-hint {
  font-size: 12px !important;
}

.flexGrid {
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-end !important;
}

@media only screen and (max-width: 690px) {
  .hide-md {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .hide-lg {
    display: none;
  }
}