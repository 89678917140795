.onboarding-process-biometric-result-title {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600 !important;
  font-size: 36px !important;
  margin-top: 20px !important;
}

.onboarding-process-biometric-result-description {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.onboarding-process-biometric-result-steps {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  margin-bottom: 30px;
}

@media only screen and (max-width: 800px) {
  .onboarding-process-biometric-result-title {
    font-size: 28px !important;
    text-align: left;
  }

  .onboarding-process-biometric-result-description {
    font-size: 18px !important;
    text-align: left;
  }

  .onboarding-process-biometric-result-steps {
    font-size: 18px !important;
  }
}