.document-load-title {
  font-weight: 700 !important;
  font-family: 'Poppins',sans-serif !important;
  font-size: 36px !important;
  color: #4a4a4a !important;
}

.document-load-renewer-title {
  font-family: 'Poppins',sans-serif !important;
  font-size: 36px !important;
  color: #4a4a4a !important;
}

.document-load-subtitle {
  margin-top: 20px !important;
  font-weight: 500 !important;
  font-family: 'Poppins',sans-serif !important;
  font-size: 24px !important;
  color: #4a4a4a !important;
}

.document-load-description {
  font-family: 'Poppins',sans-serif !important;
  font-size: 18px !important;
}

@media only screen and (max-width: 800px) {
  .document-load-title {
    font-size: 28px !important;
  }

  .document-load-renewer-title {
    font-size: 28px !important;
  }

  .document-load-subtitle {
    font-size: 18px !important;
  }
}